const titleOverride = ref<string | null>(null)

export const useRouteTitle = () => {
  const $i18n = useI18n()
  const $route = useRoute()

  const computedTitle = computed(() => {
    const pageTitleValue = titleOverride.value || ($route.meta.title as string) || "SAM Beheer"

    return $i18n.te(pageTitleValue) ? $i18n.t(pageTitleValue) : pageTitleValue
  })

  watch(
    () => $route.path,
    () => (titleOverride.value = null),
  )

  watch(
    computedTitle,
    () => {
      useHead({
        title: computedTitle.value,
        titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - SAM Beheer` : "SAM Beheer"),
        htmlAttrs: {
          lang: $i18n.locale.value,
          translate: "no",
          class: "notranslate",
        },
      })

      if (typeof document !== "undefined") {
        document.title = `SAM${computedTitle.value ? " - " + computedTitle.value : ""}`
      }
    },
    { immediate: true },
  )

  return {
    computedTitle,
    titleOverride,
  }
}
